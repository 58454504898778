import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { CommonService, Constant, EmployeeService, UploadFilesService } from 'src/app/shared';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { TimesheetService } from 'src/app/shared/service/timesheet.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-timesheet-manage',
  templateUrl: './timesheet-manage.component.html',
  styleUrls: ['./timesheet-manage.component.css']
})
export class TimeSheetManageComponent implements OnInit { 

  tisToken: any = null;
  searchForm: FormGroup;
  addForm: FormGroup;
  editForm: FormGroup;
  submitted_add = false;
  submitted_edit = false;
  submitted_search = false;
  masterDataList = [];
  yearList;
  tmpEmpSelect = [];
  optionleDate: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
    singleDatePicker: false
  }; 
  startDate;
  startDateStr;
  endDate;
  endDateStr;
  optionleAddDate: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
    singleDatePicker: true
  }; 
  addDate;
  addDateStr;
  uploadTmpList = [];
  timesheetList = [];
  employeeId;
  employee: any;
  currentId;
  timesheet: any;

  //Employee Filtered
  public employeeCtrl: FormControl = new FormControl();
  public employeeFilterCtrl: FormControl = new FormControl();
  public filteredEmployeeList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroyEmployee = new Subject<void>();

  constructor(
    private employeeService: EmployeeService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private uploadService: UploadFilesService,
    private commonService: CommonService,
    private timesheetService: TimesheetService,
    private activatedRoute: ActivatedRoute,
    private constant: Constant
    ) { 
      this.searchForm = fb.group({
        'employee_id': ['',Validators.required],
        'status': ''
      });
      this.addForm = fb.group({
        'employee_id': [''],
        'work_date': [''],
        'status': ['',Validators.required],
        'details': ['']
      });
      this.editForm = fb.group({
        'employee_id': [''],
        'work_date': [''],
        'status': ['',Validators.required],
        'details': [''],
        'id': ['', Validators.required],
      });
   }

  ngOnInit() {
    this.tisToken = JSON.parse(localStorage.getItem('tisToken'));
    //this.getMasterDataActive();
    //this.search(this.searchForm.value);
    this.formDataEmployee = new FormGroup({
      valueSelect: new FormControl("")
    });
    this.getAllForDropDownEmployee();
    this.generateYearList();
    //initail date
    this.addDate = new Date();
    this.addDateStr = this.getDateStr(this.addDate);
    this.startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.endDate = new Date();

    //get params
    this.activatedRoute.params.forEach((urlParams) => {
      this.employeeId = urlParams['id'].replace('#', '');
      console.log("employeeId : "+this.employeeId); 
      if(this.employeeId != 0){
        this.searchForm.patchValue({
          employee_id: this.employeeId,
        });
        this.getEmployeeById(this.employeeId);
        this.search();
      }
    });
  }

  getEmployeeById(id){
    this.employeeService.getEmployeeByIdV2(id).subscribe(res=>{
      console.log(res);
      this.employee = res;
    })
  }

  generateYearList(){
    this.yearList = this.commonService.generateYearList();
  }

  formDataEmployee: FormGroup;
  isShowEmployee = false;
  getAllForDropDownEmployee(){
    this.spinner.show();
    this.employeeService.getInternalEmployee().subscribe(datas=>{
      //console.log(datas);

      this.employeeList = Object.keys(datas).length === 0 ? [] : datas;
      // load the initial bank list
      this.filteredEmployeeList.next(this.employeeList.slice());

      // listen for search field value changes
      this.employeeFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroyEmployee))
        .subscribe(() => {
          this.filterEmployee();
      });

      setTimeout(() => {
        this.isShowEmployee = true;

        this.formDataEmployee
        .get("valueSelect")
        .setValue(0);

        this.spinner.hide();
      }, 100);

    }, err => {
      this.spinner.hide();
      var msg = 'error';
      console.error(err);
      this.failDialog(msg);
    });
  }

  employeeList = [];
  protected filterEmployee() {
    if (!this.employeeList) {
      return;
    }
    // get the search keyword
    let search = this.employeeFilterCtrl.value;
    if (!search) {
      this.filteredEmployeeList.next(this.employeeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredEmployeeList.next(
      this.employeeList.filter(x => (x.first_name+" "+x.last_name).toLowerCase().indexOf(search) > -1)
    );
  }

  changeEmployee(data){
    console.log(data);
    this.employee = data;
    this.searchForm.patchValue({
      employee_id: data.id,
    });
    console.log(this.searchForm.value)
    this.search();
  }

  /*clickedSearch(){
    console.log('click search');
    this.employee = null;
    this.search();
  }*/

  search(){
    console.log('search');
    this.submitted_search = true;
    if(this.searchForm.invalid){
      return;
    }
    this.spinner.show();
    let criteria = this.searchForm.value;
    criteria = {
      ...criteria,
      startDate: this.startDate ? this.convertToDateSearch(this.startDate) : null,
      endDate:this.endDate ? this.convertToDateSearch(this.endDate) : null
    }
    console.log(criteria);
    this.timesheetService.searchByCriteria(criteria).subscribe(res => {
      console.log(res);
      this.timesheetList = [];
      let datas = res.data;
      for (let day = new Date(this.startDate); day <= this.endDate; day.setDate(day.getDate() + 1)) {
        let isDupDate = false;
        let dupIndex = 0;
        for(let i=0; i<datas.length;i++){
          if(datas[i].work_date.split(' ')[0] === this.convertToDateSearch(day)){
            isDupDate = true;
            dupIndex = i;
          }
        }
        if(isDupDate){
          this.timesheetList.push(datas[dupIndex]);
        }else if(this.searchForm.value.status === '' && (day <= new Date())){
          this.timesheetList.push({work_date: this.convertToDateSearch(day), status: undefined});
        }
      }
      this.spinner.hide();
    });
  }

  saveTimesheet(value){
    this.submitted_add = true;
    if(this.addForm.invalid){
      return;
    }
    if(this.tisToken){
      value['employee_id'] = this.tisToken.id;
    }
    if(this.addDate){
      let splitAddDate = this.addDateStr.split('-');
      value['work_date'] = splitAddDate[2]+'-'+splitAddDate[1]+'-'+splitAddDate[0];
    } 
    console.log(value);
    this.timesheetService.createOrUpdate(value).subscribe(data=>{
      $('#modal-timesheet-add').modal('hide');
      console.log(data);
      if(this.selectedFile1){
        this.uploadFile(data.data,this.selectedFile1,this.selectedFile1.name,null,0,1);
      }
      if(this.selectedFile2){
        this.uploadFile(data.data,this.selectedFile2,this.selectedFile2.name,null,0,2);
      }
      if(this.selectedFile3){
        this.uploadFile(data.data,this.selectedFile3,this.selectedFile3.name,null,0,3);
      }
      this.successDialog();
      this.search();
    }, error => {
      console.error(error);
      this.failDialog(error);
    });
  }

  message: string = '';
  uploadFile(data, file, name, fileInfoMap,docId, seq) {
    console.log(file);
    this.progressInfos[0] = { value: 0, fileName: file.name };
    var formData: FormData = new FormData();
    console.log(file);
    formData.append('file', file);
    formData.append('type', "timesheet");
    formData.append('timesheet_id', data.id);
    formData.append('name', name);
    formData.append('docId', docId);
    formData.append('seq', seq);

    this.uploadService.upload(formData).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos[0].value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          console.log(event);
          if(event.body.code === "success" && event.body.data){
            //this.successDialog();
            //this.getProject();
          }
        }
      },
      err => {
        this.progressInfos[0].value = 0;
        this.message = 'Could not upload the file:' + file.name;
        console.log(err); 
      });
  }

  openModalDelete(id){
    this.currentId = id;
    console.log('id',this.currentId);
    $('#modal-delete-timesheet').modal('show');
  }

  delete(currentId){
    $('#modal-delete-timesheet').modal('hide');
    this.timesheetService.deleteTimesheet(currentId).subscribe(res=>{
      console.log(res);
      this.successDialog();
      this.search();
    }, error => {
      console.error(error);
      this.failDialog(error);
    });
  }

  progressInfos = []
  selectedFile1: any
  selectedFile2: any
  selectedFile3: any
  selectFile(event, type) {
    this.progressInfos = [];
    if(type === 1){
      this.selectedFile1 = event.target.files[0];
    }else if(type === 2){
      this.selectedFile2 = event.target.files[0];
    }else if(type === 3){
      this.selectedFile3 = event.target.files[0];
    }
    //this.selectedFile = event.target.files[0];
    console.log(this.selectedFile1);
    console.log(this.selectedFile2);
    console.log(this.selectedFile3);
  }

  selectedFileEdit1: any
  selectedFileEdit2: any
  selectedFileEdit3: any
  selectEditFile(event, type) {
    this.progressInfos = [];
    if(type === 1){
      this.selectedFileEdit1 = event.target.files[0];
    }else if(type === 2){
      this.selectedFileEdit2 = event.target.files[0];
    }else if(type === 3){
      this.selectedFileEdit3 = event.target.files[0];
    }
    //this.selectedFile = event.target.files[0];
    console.log(this.selectedFileEdit1);
    console.log(this.selectedFileEdit2);
    console.log(this.selectedFileEdit3);
  }

  export(){
    console.log('export');
    this.submitted_search = true;
    if(this.searchForm.invalid){
      return;
    }
    this.spinner.show();
    let criteria = this.searchForm.value;
    let timesheetList = JSON.parse(JSON.stringify(this.timesheetList));
    timesheetList.forEach(item => {
      if(item.work_date) {
        item.work_date = this.displayWorkDate(item.work_date);
      }
      if(!item.status) {
        item.status = "";
      }
      if(!item.details) {
        item['details'] = "";
      }
      if(item.updated_at) {
        item['updated_at'] = this.displayUpdateDate(item['updated_at']);
      }else{
        item['updated_at'] = "";
      }
    });
    criteria = {
      ...criteria,
      startDate: this.startDate ? this.convertToDateSearch(this.startDate) : null,
      endDate:this.endDate ? this.convertToDateSearch(this.endDate) : null,
      timesheetList: timesheetList
    }
    console.log(criteria);
    const parentThis = this;
    this.timesheetService.export(criteria).subscribe(res=>{
      parentThis.spinner.hide();
      window.open(res.data);
    });
  }


  fileEdit1: any
  fileEdit2: any
  fileEdit3: any
  openModalEdit(data){
    console.log(data);
    //Clear old file patch
    this.fileEdit1 = undefined;
    this.fileEdit2 = undefined;
    this.fileEdit3 = undefined;
    this.editForm.patchValue({
      employee_id: data.employee_id, work_date: data.work_date,
      status: data.status, details: data.details,id: data.id
    });
    this.timesheet = data;
    if(this.timesheet.fileList){
      //file1
      let files = this.timesheet.fileList.filter(file => file.seq == 1);
      if(files.length > 0){
        this.fileEdit1 = files[0];
      }
      //file2
      files = this.timesheet.fileList.filter(file => file.seq == 2);
      if(files.length > 0){
        this.fileEdit2 = files[0];
      }
      //file3
      files = this.timesheet.fileList.filter(file => file.seq == 3);
      if(files.length > 0){
        this.fileEdit3 = files[0];
      }
    }   
    $('#modal-timesheet-edit').modal('show');
  }

  updateTimesheet(value){
    console.log('updateTimesheet');
    console.log(value);
    //return;
    this.submitted_edit = true;
    if(this.editForm.invalid){
      return;
    }
    console.log(value);
    this.timesheetService.createOrUpdate(value).subscribe(data=>{
      $('#modal-timesheet-edit').modal('hide');
      console.log(data);
      if(this.selectedFileEdit1){
        this.uploadFile(data.data,this.selectedFileEdit1,this.selectedFileEdit1.name,null,0,1);
      }else{
        if(this.fileEdit1){
          this.selectedFileEdit1 = this.commonService.dataURLtoFile(this.fileEdit1.url,this.fileEdit1.name_origin);
          this.uploadFile(data.data,this.selectedFileEdit1,this.selectedFileEdit1.name,null,0,1);
        }
      }
      if(this.selectedFileEdit2){
        this.uploadFile(data.data,this.selectedFileEdit2,this.selectedFileEdit2.name,null,0,2);
      }else{
        if(this.fileEdit2){
          this.selectedFileEdit2 = this.commonService.dataURLtoFile(this.fileEdit2.url,this.fileEdit2.name_origin);
          this.uploadFile(data.data,this.selectedFileEdit2,this.selectedFileEdit2.name,null,0,2);
        }
      }
      if(this.selectedFileEdit3){
        this.uploadFile(data.data,this.selectedFileEdit3,this.selectedFileEdit3.name,null,0,3);
      }else{
        if(this.fileEdit3){
          this.selectedFileEdit3 = this.commonService.dataURLtoFile(this.fileEdit3.url,this.fileEdit3.name_origin);
          this.uploadFile(data.data,this.selectedFileEdit3,this.selectedFileEdit3.name,null,0,3);
        }
      }

      //Clear file
      this.selectedFileEdit1 = undefined;
      this.selectedFileEdit2= undefined;
      this.selectedFileEdit3 = undefined;
      this.fileEdit1 = undefined;
      this.fileEdit2 = undefined;
      this.fileEdit3 = undefined;
      this.successDialog();
      setTimeout(() => {location.reload();}, 2000);
      //this.search();
    }, error => {
      console.error(error);
      this.failDialog(error);
    });
  }

  selectedDate(value: any, datepicker?: any) {
    //start date
    this.startDate = value.start._d;
    this.startDateStr = this.getDateStr(this.startDate);
    console.log(this.startDateStr);
    //end date
    this.endDate = value.end._d;
    this.endDateStr = this.getDateStr(this.endDate);
    console.log(this.endDateStr);
  }

  selectedAddDate(value: any, datepicker?: any) {
    this.addDate = value.start._d;
    this.addDateStr = this.getDateStr(this.addDate);
    console.log(this.startDateStr);
  }

  getDateStr(date){
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    return dd + '-' + mm + '-' + yyyy;
  }

  convertToDateSearch(date){
    //console.log(date);
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  }

  displayWorkDate(date){
    if(date){
      let splitDate = date.split(' ')[0].split('-');
      return splitDate[2] + '-' + this.commonService.convertMonthTH(splitDate[1]) + '-' + splitDate[0];
    }
    return "-";
  }

  displayUpdateDate(date){
    if(date){
      let splitDate = date.split(' ')[0].split('-');
      let value = splitDate[2].concat(' ').concat(this.commonService.convertMonthTH(splitDate[1])) + ' '.concat(splitDate[0])
      .concat(' ' + date.split(' ')[1]).concat(' น.')
      return value;
    }
    return "-";
  }

  isAccess(){
    //console.log(this.employeeId)
    if(this.employeeId == 0){
      return true;
    }
    return false;
  }

  isDelete(data){
    if(!data.status){
      return false;
    }
    let dateCompare = new Date(new Date().setDate(new Date().getDate() - 6));
    if(new Date(data.work_date).setHours(0, 0, 0, 0) <= dateCompare.setHours(0, 0, 0, 0)){
      return false;
    }
    return true;
  }

  isEdit(data){
    if(!data.status){
      return false;
    }
    let dateCompare = new Date(new Date().setDate(new Date().getDate() - 6));
    if(new Date(data.work_date).setHours(0, 0, 0, 0) <= dateCompare.setHours(0, 0, 0, 0)){
      return false;
    }
    return true;
  }

  isSuperAdmin(){
    if(this.tisToken.id == 2 
      || this.tisToken.id == 45 
      || this.tisToken.id == 2925
      || this.tisToken.id == 2018){
      return true;
    }
    return false;
  }
 
  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
